<template>
  <ContainerCard :locationMsg="locationMsg" width="90">
    <div class="Industry">
      <!-- <Technology @techHandle="techHandle" :title="title"></Technology> -->
      <IndustryTerritory @techHandle="techHandle" :title="title"></IndustryTerritory>
      <div class="companys" v-loading="fullscreenLoading" v-empty="List">
        <div
          v-for="(item, index) in List"
          :class="['company', index % 2 == 0 ? 'company-left' : 'company-right']"
          :key="index"
          @click="toIndustryDetails(item.dieID)"
        >
          <div class="top">
            <el-image
              class="logo"
              :src="httpConfig.baseURL+item.logo || defalutImg"
              alt=""
              fit="contain"
              v-if="item.logo"
            />
            <div v-if="!item.logo" class="no-logobox">
              <el-image class="no-logo" :src="defalutImg" alt="" />
            </div>
            <div class="topRight">
              <div class="name">
                <span>{{ item.name }}</span>
              </div>
              <div v-if="item.indusList.length > 0" class="type">
                <div
                  class="label"
                  v-for="(item,index) in item.indusList"
                  :key="index"
                  onClick="event.cancelBubble = true"
                >
                  {{item}}
                </div>
              </div>
              <div class="address">
                <span class="label">单位地址</span>
                <span class="value">{{ item.address }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <Pagination
        @changeIndex="setCurrentPage"
        :total="total"
        :totalPage="totalPage"
        :pageSize="params.pageSize"
      ></Pagination>
    </div>
  </ContainerCard>
</template>
<script>
import Location from "../../components/Location.vue";
// import Technology from "../../components/Info/Technology.vue";
import IndustryTerritory from '@/components/Info/IndustryTerritory';
import ContainerCard from "../../components/newContainerCard.vue";
import { getIndustrys } from "../../api/industry/industry.js"; // TODO: 接口分页器 失效
import {getIndustryfield} from "../../api/home/home.js";
import { mapGetters } from "vuex";
import Pagination from "../../components/Pagination.vue";
import defalutImg from "../../assets/images/暂无数据默认图片.png";
import httpConfig from "../../common/utils/config";
import { res as RES } from './data'
export default {
  computed: {
    ...mapGetters(["techfieldList"]),
  },
  data() {
    return {
      httpConfig,
      List: [],
      locationMsg: [],
      total: 0,
      totalPage: 0,
      fullscreenLoading: false,
      title:'行业领域',
      params: {
        industry:"",//行业领域
        // tech_field: "", //技术领域 
        keyword: "",
        orderstr: "make_date", //click_num
        pageNo: 1,
        pageSize: 15,
      },
      defalutImg,
      industryList :[],
    };
  },
  created() {
    this.getData();
    
  },
  methods: {
    // 自定义事件techHandle的函数回调。
    techHandle(val) {
      this.List = []
      console.log("val是:",val)
      this.params.industry = val;  
      this.getData();
    },
    setCurrentPage(val) {
      this.params.pageNo = val;
      this.getData();
    },
    searchHandle(val) {
      this.params.keyword = val;
      if (!val) {
        this.params.type = "";
      }
      this.getData();
    },
    async getData() {
      let that = this;
      that.fullscreenLoading = true;

      // let industryData = await getIndustryfield();
      let industryData = await getIndustryfield();
      that.industryList = industryData.data;


      // let res = await getIndustrys(that.params);
      let res = []
      if (that.params.industry) {
        res = RES.filter(item => item.tech_field.split(',').includes(that.params.industry))
      } else {
        res = RES
      }
      that.List = res.map((item) => {
        let tmp = item;
        tmp.techList = [];
        Object.keys(that.industryList).forEach(function (key) {
          if (
            tmp.tech_field &&
            tmp.tech_field.split(",").indexOf(that.industryList[key]) > -1
          ) {
            tmp.techList.push(key);
          }
        });
        return tmp;
      });
      this.total = res.length;
      this.totalPage = 1
      this.totalPage = res.length
      // this.totalPage = Math.ceil(res.totalCount / this.params.pageSize);
      that.fullscreenLoading = false;
      console.log("List数据:",this.List)
      // console.log("techList的数据:",this.List[0])
    },

    toIndustryDetails(id) {
      this.$router.push({
        path: "/industry/industryDetails",
        query: { dieID: id },
      });
    },
  },
  components: {
    Pagination,
    ContainerCard,
    Location,
    // Technology,
    IndustryTerritory
  },
};
</script>
<style scoped lang="scss">
.Industry {
  width: 1260px;
}
.pagination {
  height: 138px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.companys {
  display: grid;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0px 0px;
  // margin-top: 22px;
  width: 1260px;
}

.company-left {
  padding-left: 0px;
  border-right: 1px solid #f1f1f1;
  &:hover {
    padding: 30px;
  }
}

.company-right {
  padding-left: 30px !important;
}
.company {
  background: #ffffff;
  box-sizing: border-box;
  width: 630px;
  height: 150px;
  // box-shadow: 0px 0px 3px 2px rgba(228, 228, 228, 0.5);
  padding: 20px 16px 16px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #f1f1f1;
  // border-right: 1px solid #f1f1f1;
  // &:nth-child(odd) {
  //   border-right: 1px solid #f1f1f1;
  //   padding-left: 30px;
  // }
  // &:nth-child(even) {
  //   border-left: 0px solid #f1f1f1;
  //   border-right: 1px solid #f1f1f1;
  //   // padding-left: 30px;
  //   &:hover {
  //     padding-left: 30px;
  //   }
  // }
  &:hover {
    box-shadow: 0px 12px 40px rgba($color: #000000, $alpha: 0.12);
    z-index: 999;
  }
  .top {
    display: flex;
    width: 100%;
    .logo {
      width: 90px;
      height: 90px;
      margin-right: 20px;
      border-radius: 6px;
    }
    .no-logobox {
      width: 90px;
      height: 90px;
      margin-right: 20px;
      background: #F1F1F1;
      text-align: center;
      line-height: 90px;
      border-radius: 6px;
      .no-logo {
        width: 24px;
        height: 24px;
      }
    }
    .topRight {
      margin-left: 12px;
      flex: 1;
      .name {
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        font-family: Medium;
        font-weight: 600;
        color: #101010;
        margin-bottom: 13px;
      }
    }
    .type {
      display: flex;
      flex-wrap: wrap;
      // margin-bottom: 13px;
      // margin-top: 13px;
      margin-bottom: 13px;
      .label {
        border-radius: 3px;
        line-height: 18px;
        margin-right: 12px;
        border: 1px solid #2885ff;
        padding: 1.5px 5px;
        font-size: 12px;
        font-family: Regular;
        font-weight: 400;
        color: #ffffff;
        background-color: #2885ff;
      }
    }
    .address {
      display: flex;
      align-items: center;
      .label {
        font-size: 12px;
        font-family: Regular;
        font-weight: 400;
        color: #8896a7;
        margin-right: 20px;
      }

      .value {
        display: inline-block;
        width: 360px;
        overflow: hidden;
        line-height: 18px;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        font-family: 思源黑体 CN Regular;
        font-weight: 400;
        color: #404040;
        // margin-right: 20px;
      }
    }
  }
  hr {
    margin-top: 36px;
    flex: 1;
    width: 100%;
    height: 1px;
    border: none;
    border-bottom: 1px solid #eaeaea;
  }
  .bottom {
    display: flex;
    margin-top: 16px;
    align-items: center;
    .icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
    .site {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: 1100px) {
}

@media screen and (max-width: 768px) {
}
</style>