const res = [
  {
    dieID: "1",
    tech_field: "1",
    name: "利郎（中国）有限公司",
    indusList: ["纺织鞋服"],
    address: "晋江市科技工业园区",
    company_size: 4,
    mobile: "1835959****",
    memo: "利郎（中国）有限公司成立于2005年03月25日，注册地位于晋江市科技工业园区，法定代表人为王冬星。经营范围包括从事服装文化、设计、研发；生产加工针纺织品、纺织原料、化纤原料；服装、服饰、各种鞋、家私、五金（不含电镀）、塑料制品的生产、批发、零售（以上商品进出口不涉及国营贸易、进出口配额许可证、出口配额招标、出口许可证等专项管理的商品）。（依法须经批准的项目，经相关部门批准后方可开展经营活动）利郎（中国）有限公司对外投资2家公司，具有3处分支机构。"
  },
  {
    dieID: "2",
    tech_field: "5",
    name: "福建盼盼食品有限公司",
    indusList: ["食品饮料"],
    address: "福建省泉州市晋江市安海镇前蔡工业区",
    company_size: 4,
    mobile: "15280815097",
    memo: "福建盼盼食品有限公司成立于2014年04月24日，注册地位于晋江市安海前蔡工业区，法定代表人为蔡金垵。经营范围包括许可项目：食品生产；食品销售（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准）一般项目：玩具销售（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）福建盼盼食品有限公司对外投资11家公司，具有7处分支机构。"
  },
  {
    dieID: "3",
    tech_field: "4",
    name: "福建建利达工程技术有限公司",
    indusList: ["建材家居"],
    address: "泉州台商投资区洛阳镇屿头村10组",
    company_size: 4,
    mobile: "",
    memo: "福建建利达工程技术有限公司成立于2005年03月24日，注册地位于泉州台商投资区洛阳镇屿头村10组，法定代表人为黄妤群。经营范围包括许可项目：建设工程质量检测；水利工程质量检测；雷电防护装置检测；检验检测服务；室内环境检测；安全生产检验检测；特种设备检验检测；建设工程勘察；安全评价业务。（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准）一般项目：安全咨询服务；公路水运工程试验检测服务；环境保护监测；消防技术服务；软件开发；工程和技术研究和试验发展；技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广。（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）福建建利达工程技术有限公司对外投资1家公司，具有6处分支机构。"
  },
  {
    dieID: "4",
    tech_field: "1",
    name: "惠安县双喜制衣有限公司",
    indusList: ["纺织鞋服"],
    address: "福建省惠安县洛阳镇",
    company_size: 4,
    mobile: "0595-8748****",
    memo: "惠安县双喜制衣有限公司成立于1988年12月24日，注册地位于福建省惠安县洛阳镇，法定代表人为杨培峰。经营范围包括生产及销售各种服装、服饰、针织品、箱包、雨伞、非医用日用防护口罩、非医用日用防护服；非配额许可证管理产品、非专营商品的收购出口业务，并参加自产产品的出口配额招标。（依法须经批准的项目，经相关部门批准后方可开展经营活动）惠安县双喜制衣有限公司对外投资1家公司，具有4处分支机构。"
  },
  {
    dieID: "5",
    tech_field: "13",
    name: "福建莱仁家政有限公司1",
    indusList: ["健康服务"],
    address: "泉州市丰泽区西湖街至真堂大厦B座603",
    company_size: 4,
    mobile: "0595-2725****",
    memo: "福建莱仁家政有限公司成立于2013年04月24日，注册地位于泉州市丰泽区西湖街至真堂大厦B座603，法定代表人为陈福龙。经营范围包括家政服务；病人陪护服务；建筑劳务分包；人力资源服务；养老机构业务，为老年人提供社区托养、居家照护等社区养老服务；单位后勤管理服务；母婴护理服务；物业管理；建筑物清洁服务；城市垃圾清运、处理服务；搬家搬厂服务；洗涤服务；餐饮管理；对医疗产业、养老产业、疗养行业进行投资；代办医保、社保业务；企业管理咨询（不含金融、证券、期货的投资咨询）；文化艺术交流活动组织、策划；房产中介服务；室内外装修工程、水电安装工程、园林绿化工程的设计、施工；四害消杀服务；软件技术开发、技术咨询、技术服务、技术转让及销售；销售：一类医疗器械、家用电器、清洁设备、洗涤用品、日用百货。（依法须经批准的项目，经相关部门批准后方可开展经营活动）福建莱仁家政有限公司对外投资3家公司，具有1处分支机构"
  },
  {
    dieID: "6",
    tech_field: "4",
    name: "九牧厨卫股份有限公司",
    indusList: ["建材家居"],
    address: "福建省南安经济开发区九牧工业园",
    company_size: 4,
    mobile: "0595-8614****",
    memo: "九牧集团创立于1990年，是一家以智能卫浴为核心，集研发、制造、营销、服务于一体的全产业链、创新型国际化企业，全球设有16个研发中心，在德国、法国拥有高端灯塔工厂，行业首创零碳灯塔工厂、行业首家5G灯塔工厂。在健康新技术、系统数字化、轻智能、定制化、新颜值、新材料、新能源、生态品类研发等方面继续发挥领先优势，积极响应绿色发展号召，率先实施“双碳”行动计划，促进人、产品、环境的和谐发展，持续为全球用户创造美好家居生活新体验。"
  },
  {
    dieID: "7",
    tech_field: "1",
    indusList: ["纺织鞋服"],
    name: "信泰（福建）科技有限公司",
    address: "福建省泉州市晋江市经济开发区（五里园）裕源路10号",
    company_size: 4,
    mobile: "15027340243",
    memo: "信泰（福建）科技有限公司成立于2005年05月11日，注册地位于福建省泉州市晋江市经济开发区（五里园）裕源路10号-1至-2，法定代表人为许金升。经营范围包括一般项目：工程和技术研究和试验发展；制鞋原辅材料制造；面料纺织加工；产业用纺织制成品生产；针织或钩针编织物及其制品制造；家用纺织制成品制造；家具制造；汽车装饰用品销售；鞋制造；鞋帽批发；鞋帽零售；产业用纺织制成品销售；制鞋原辅材料销售；针纺织品及原料销售；以自有资金从事投资活动；高性能纤维及复合材料制造；合成纤维制造；合成纤维销售；高性能纤维及复合材料销售（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）许可项目：货物进出口。"
  },
  {
    dieID: "8",
    tech_field: "13",
    indusList: ["健康服务"],
    name: "福建省乐意帮家政服务有限公司",
    address: "福建省泉州市丰泽区泉州农校综合培训楼7楼",
    company_size: 3,
    mobile: "0595-2725****",
    memo: "福建省乐意帮家政服务有限公司成立于2011年05月03日，注册地位于泉州市丰泽区坪山路泉州市农校综合培训楼七楼，法定代表人为康天南。经营范围包括家政服务；家政咨询；保洁服务；搬家服务；货物运输；园林绿化施工与养护；绿化管理；房产中介服务；人力资源服务（不含劳务派遣）；家电安装与维修；普通货物装卸；物业管理；单位后勤管理；为老年人提供社区托养、居家照护等社区养老服务；婚姻介绍；石材护理；研发、生产、销售：清洁用品、环保用品；环境卫生管理；河道清理养护；照明工程维护；虫害防控；四害消杀；软件技术研发；网站建设与维护；第二类增值电信业务中的呼叫中心业务；互联网信息服务（不含网吧）；建筑劳务分包（不含劳务派遣服务）"
  },
  {
    dieID: "9",
    tech_field: "3",
    indusList: ["智能装备"],
    name: "泉州华数机器人有限公司",
    address: "泉州市经济技术开发区崇宏街288号",
    company_size: 3,
    mobile: "0595-2885****",
    memo: "泉州华数机器人有限公司成立于2015年05月07日，注册地位于泉州市经济技术开发区崇宏街288号，法定代表人为王群。经营范围包括机器人产品、机电一体化产品、自动化生产装备、自动控制设备、驱动装置、计算机软件、机电设备及系统的研发、制造、销售及售后服务；自营和代理各类商品和技术的进出口"
  },
  {
    dieID: "10",
    tech_field: '',
    indusList: [],
    name: "福建司雷植保技术有限公司",
    address: "福建省泉州市安溪县龙门镇中国国际信息技术(福建)产业园数据中心业务处理楼5层",
    company_size: 3,
    mobile: "0595-2626****",
    memo: "福建司雷植保技术有限公司成立于2017年05月24日，注册地位于福建省泉州市安溪县龙门镇中国国际信息技术(福建)产业园数据中心业务处理楼5层，法定代表人为陈向东。经营范围包括一般项目：农作物病虫害防治服务；林业有害生物防治服务；农业专业及辅助性活动；技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；农业科学研究和试验发展；智能无人飞行器销售；环境保护监测；农产品的生产、销售、加工、运输、贮藏及其他相关服务；数据处理和存储支持服务；计算机软硬件及辅助设备批发；机械设备销售；农业机械销售；农业机械制造；农业机械服务；农林牧副渔业专业机械的安装、维修；电子产品销售；水产品批发；水产品零售；茶叶种植；农副产品销售（除依法须经批准的项目外，凭营业执照依法自主开展经营活动） 许可项目：测绘服务；农产品质量安全检测；货物进出口；技术进出口；食品经营；茶叶制品生产；农药批发；农药零售"
  },
  {
    dieID: "11",
    tech_field: '',
    indusList: [],
    name: '福建安溪灿鹏汽车服务有限公司',
    address: '福建省泉州市安溪县城厢镇华侨职业中专学校实训楼1',
    company_size: 3,
    mobile: "1390598****",
    memo: "福建安溪灿鹏汽车服务有限公司成立于2015年12月10日，注册地位于福建省泉州市安溪县城厢镇华侨职业中专学校实训楼1-2楼，法定代表人为苏明灿。经营范围包括汽车维修、维护；汽车美容、装饰；汽车用品、汽车轮胎、汽车配件、二手车销售；汽车维修咨询服务；车身改装。"
  },
  {
    dieID: "12",
    tech_field: '7',
    indusList: ['新材料'],
    name: "福建省佑达环保材料有限公司",
    address: "福建省泉州市泉港区南埔镇石化园区南山片区天盈路8号",
    company_size: 3,
    mobile: "0595-2772****",
    memo: "福建省佑达环保材料有限公司成立于2017年05月24日，注册地位于福建省泉州市泉港区南埔镇石化园区南山片区天盈路8号，法定代表人为刘小勇。经营范围包括环保材料销售与研发，光电微电子及半导体高新技术材料（含湿电子化学品）（按《安全生产许可证》所列范围及环保部门批准的项目范围，不含危险化学品）的研发、生产、销售；化学工程技术的研发；化学品再生处理（不含危险化学品）；货物或技术进出口（国家禁止或涉及行政审批的货物和技术进出口除外）；生产、销售：医疗器械、化妆品及卫生用品、非医用日用防护口罩。"
  },
  {
    dieID: "13",
    tech_field: '16',
    indusList: ["工艺制品"],
    name: "福建南方路面机械股份有限公司",
    address: "泉州市丰泽区高新产业园体育街700号",
    company_size: 4,
    mobile: "0595-2290****",
    memo: "福建南方路面机械股份有限公司于1997年05月09日成立。2022年8月5日，证监会公告，福建南方路面机械股份有限公司首发8月11日上会。 2022年8月11日，据证监会公告，福建南方路面机械股份有限公司首发申请获通过。 2022年10月，证监会核准福建南方路面机械股份有限公司公开发行不超过2710.17万股新股。 2022年10月27日，福建南方路面机械股份有限公司新股申购。2022年11月8日，上交所主板的南方路机新股上市，南方路机发行总数2710万股，网上发行2439万股，IPO发行价格23.75元/股，发行市盈率18.37倍。"
  },
  {
    dieID: "14",
    tech_field: '',
    indusList: [],
    name: "福建省泉州市古建筑有限公司",
    address: "福建省泉州市丰泽区温陵北路北拓7栋207号",
    company_size: 2,
    mobile: "13799478767",
    memo: "福建省泉州市古建筑有限公司成立于1989年07月04日，注册地位于泉州市丰泽区温陵北路北拓7#207室，法定代表人为蒋钦全。经营范围包括仿古建筑工程，古建筑及近现代文物建筑工程修缮、保养；重要史迹及代表性建筑、古文化石窟寺和石刻修缮、保养；古文化遗址、古墓葬修缮、保养；寺庙佛像装饰；壁画及彩绘修缮、保养；城市园林绿化；市政公用工程施工；水利建设及道路桥梁工程施工；木、石雕刻施工；雕塑工程、钢结构工程设计、建造、安装；木、石、铜雕、树脂脱胎工艺品的设计、制作、安装及销售；工程设计；害虫防治服务；建筑工程施工总承包相应资质等级承包工程范围的工程施工；建筑幕墙工程、建筑装修装饰工程、消防设施工程、地基基础工程专业承包相应资质等级承包工程范围的工程施工；古建筑工程技术的研发及技术咨询；建材批发（不含危险化学品）；建筑施工劳务。"
  },
  {
    dieID: "15",
    tech_field: '',
    indusList: [],
    name: "泉州市视达电子工程有限公司",
    address: "泉州市温陵路新城大厦1幢901、1001室",
    company_size: 4,
    mobile: "0595-2220****",
    memo: "泉州市视达电子工程有限公司成立于1994年02月01日，注册地位于泉州市温陵路新城大厦1幢901、1001室，法定代表人为刘榕生。经营范围包括建筑智能化设计与施工；安全技术防范系统工程的设计、施工、维修服务；安全系统监控服务；安防系统设备租赁；城市及道路照明工程、计算机网络系统集成工程、消防设施工程设计与施工；安全检测技术服务；建筑劳务分包；安防器材、电子产品、计算机网络通信设备的研发与销售。"
  }
]

export {
  res
}