import request from '../../common/utils/request'

// 获取产业详情信息
export function getIndustryById(data) {
  return request({
    // url: "/itf/common/cjrh/industry/getIndustryById.json",
    url:"/itf/common/cjrh/industry/getIndustryById.json",
    method: "get",
    params: data
  });
}



// 翻页查询
export function getIndustrys(data) {
  return request({
    url: "/itf/common/cjrh/industry/getIndustrys.json",
    method: "get",
    params: data
  });
}

// 查询n条最新
export function getNewestEnterprise(data) {
  return request({
    url: "/itf/common/cjrh/industry/getNewestEnterprise.json",
    method: "get",
    params: data
  });
}

